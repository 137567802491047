import * as React from "react";
import { Component } from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Content from "../../../layout/Content/Content";

import logoAdidas from "./logo-adidas.png";
import logoAccenture from "./logo-accenture.png";
import logoGoogle from "./logo-google.png";
import logoIBM from "./logo-ibm.png";
import logoSamsung from "./logo-samsung.png";
import logoAtlassian from "./logo-atlassian.png";
import logoGreenpeace from "./logo-greenpeace.png";
import logoPinterest from "./logo-pinterest.png";
import logoTesla from "./logo-tesla.png";

const Container = Styled.div`
  position: relative;
  margin: 10rem 0 5rem 0;

  ${Media.greaterThan("medium")`
    margin: 10rem 0 10rem 0;
  `}
`;

const Headline = Styled.div`
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 3rem 0 3rem 0;
  color: ${(props) => props.theme.colors.gray.darker};

  ${Media.greaterThan("medium")`
    font-size: 2rem;
  `}
`;

const Articles = Styled.div`
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${Media.greaterThan("medium")`
    width: 60rem;
  `}

  ${Media.greaterThan("large")`
    width: 90rem;
  `}
`;

const Article = Styled.div`
  margin: 0 1.25rem 3rem 1.25rem; 
  text-align: center;

  ${Media.greaterThan("medium")`
    margin: 0 2.5rem 3rem 2.5rem;
  `}
`;

const Logo = Styled.img`
  display: block;
  height: 2.5rem;
  opacity: 0.3;

  ${Media.greaterThan("medium")`
    height: 3.5rem;
  `}
`;

class HomeFeatured extends Component {
  state = {
    articles: [
      {
        image: logoIBM,
        alt: "IBM",
      },
      {
        image: logoTesla,
        alt: "Tesla",
      },
      {
        image: logoGoogle,
        alt: "Google",
      },
      {
        image: logoGreenpeace,
        alt: "Greenpeace",
      },
      {
        image: logoPinterest,
        alt: "Pinterest",
      },
      {
        image: logoAtlassian,
        alt: "Atlassian",
      },
      {
        image: logoAdidas,
        alt: "Adidas",
      },
      {
        image: logoSamsung,
        alt: "Samsung",
      },
    ],
  };

  render() {
    // eslint-disable-line
    return (
      <Container>
        <Content>
          <Headline>
            {this.props.text !== undefined
              ? this.props.text
              : "Used by individuals and teams at the world's boldest companies"}
          </Headline>

          <Articles>
            {this.state.articles.map((article) => (
              <Article key={article.alt}>
                <Logo src={article.image} alt={article.alt + " logo"} />
              </Article>
            ))}
          </Articles>
        </Content>
      </Container>
    );
  }
}

export default HomeFeatured;
